:root {
    --mobile: 43rem;
    scroll-behavior: auto !important;
}
body{
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}

@import 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

@import './setting/_variables.scss';
//Importing component style
@import './component/Component.scss';
@import './component/Global.scss';