//color
$primary_color: #6C4A37;
$secondary_color :  #fbead2;
$ternary_color : #D8891A;
$forth_color: #008000;
$fifth_color: #C5A67A;
$sixth_color : #FFF7EB;




