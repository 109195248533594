//Banner
.banner {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      225.85deg,
      rgba(0, 0, 0, 0) 31.21%,
      rgba(0, 0, 0, 0.5) 52.23%,
      rgba(0, 0, 0, 0.8) 78.47%
    );
  }
  .banner-title {
    top: 7%;
  }
  .banner-menu {
    top: 7%;
    right: 7%;
    ul {
      li {
        padding-bottom: 10px;
        a {
          cursor: pointer;
          text-decoration: none;
          padding: 6px 15px;
          &:hover {
            color: #fff !important;
            background-color: $primary_color;
          }
        }
      }
      .active {
        a {
          color: #fff;
          background-color: $primary_color;
        }
      }
    }
  }

  .banner-text {
    bottom: 20%;
    padding-right: 33%;
    h1 {
      font-family: Times, sans-serif;
    }
    button {
      border-radius: 7px;
      width: 40%;
      &:first-child {
        background: linear-gradient(104.2deg, #c5a67a 32.75%, #6c4a37 136.98%);
        margin-right: 20px;
      }
      &:last-child {
        background-color: transparent;
        border: 1px solid #fff;
      }
    }
  }
}
//Prime Section
.modern-section {
  background-color: $secondary_color;
  .first-span {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
  .sec-span {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
  .facility-row {
    background: $sixth_color;
    padding: 9px 8px;

    border-radius: 12px;
    .facility-img {
      background-color: $fifth_color;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .prime-kollur {
    z-index: 100;
    border-radius: 21px;
    padding: 13px 20px;
    right: 26%;
    top: -5%;
    border: 2px solid #fff;
    h6 {
      font-size: 21px;
      font-weight: 600;
      line-height: 31.5px;
      margin-bottom: unset;
      margin-left: 10px;
    }
  }
  .second-img {
    z-index: 10;
    top: 30%;
    left: 6%;
  }
  .first-img {
    z-index: 1;
    img {
      height: 650px;
    }
  }
}
//Amenities
.amenities-section {
  .amenities-list {
    margin: 0% 12% 0% 12%;
    z-index: 10;
    // &:not(:first-child):not(:last-child){
    //     padding: unset;
    // }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #1e1e1e;
      padding-top: 30px;
      margin-bottom: unset;
    }
    .amenities-card {
      border: 1px solid $fifth_color;
      border-radius: 9px;
      padding: 10% 0px;
      background-color: #fff;
      .image-div {
        img {
          &:first-child {
            right: 30%;
            top: -13px;
          }
        }
      }
    }
  }
  .img2 {
    left: -9%;
    z-index: 1;
    bottom: 0;
  }
  .img1 {
    right: -9%;
    z-index: 1;
    bottom: 0;
  }
}
//Location
.location-section {
  background-color: $secondary_color;
  .container {
    position: relative;
    z-index: 2;
  }
  .background_img {
  }
  .map-image {
    img {
      
      // &:nth-of-type(2) {
      //     position: absolute;
      // }
      // &:nth-of-type(2) {
      //     position: absolute;
      // }
    }
  }
  .duration-row{
    p{
      text-transform: capitalize;
    }
    .duration {
      background: $primary_color;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      padding: 1% 1%;
      .duration-div {
        padding-left: 15px;
        svg{
          color: $secondary_color;
        }
        .time-display {
          display: flex;
          flex-direction: column;
          margin: 7px 0px 0px 5px;
          p {
            margin: 0;
            &:first-child {
              font-size: 24px;
            }
            &:last-child {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .location {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      color: #000;
      background-color: #fff;
      padding: 9px 17px;
      p {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  // .arrow-sign{
  //     position: absolute;
  // }
  // .project-name{
  //     position: absolute;
  // }
}
//About
.about-section {
  .container {
    z-index: 1;
  }
  ul {
    padding-left: unset;
    padding-right: 14%;
    li {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      padding-right: 10px;
      &:not(:last-child) {
        padding-bottom: 20px;
      }
      svg {
        font-size: 28px;
        color: #36c512;
        margin-right: 20px;
      }
      p {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
}
//Facing
.facing-section {
  .facing-div {
    border-radius: 26px;
    padding: 7px 12px;

    p {
      &:first-child {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
      }
      &:last-child {
        font-size: 26px;
        font-weight: 400;
        line-height: 39px;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
      }
    }
    .west-btn {
      border: 1px solid $primary_color;
    }
    button {
      padding: 10px 15px;
    }
  }
  .east-facing {
    background-color: $sixth_color;
  }
  .west-facing {
    border: 1px solid $fifth_color;
  }
}
//Testimony
.testimony-section {
  .card {
    border: none;
    border-radius: 20px;
    padding: 20px;
    .rating-star {
      svg {
        color: #f79f24;
      }
    }
    .user-content {
      .user-discription {
        margin-bottom: 20px;
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 25px;
        }
      }
      .user-data {
        img {
          position: relative;
          margin-right: 15px;
        }
        p {
          &:first-child {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
          }
          &:last-child {
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
          }
        }
      }
    }
  }
  .swiper-pagination {
    position: unset;
    text-align: end;
    margin-top: 10px;
    padding-right: 20px;
    .swiper-pagination-bullet {
      background-color: $fifth_color;
    }
    .swiper-pagination-bullet-active {
      background-color: $primary_color;
    }
  }
  button {
    box-shadow: 0px 7px 7.8px 0px #c5a67a80;
  }
}
//Offer
.offer-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 8% 5% !important;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      240.34deg,
      rgba(0, 0, 0, 0) 14.18%,
      rgba(0, 0, 0, 0.4) 56.28%,
      #000000 103.51%
    );
  }
  h2 {
    line-height: 48px;
    color: #fff;
  }
  h6 {
    font-size: 21px;
    font-weight: 400;
    line-height: 31.5px;
    span {
      font-weight: 700;
    }
  }
  h4 {
    font-size: 28px;
    font-style: italic;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.01em;
    color: $fifth_color;
  }
  .offer-coupan {
    background: linear-gradient(
      360deg,
      rgba(255, 186, 49, 0.41) 3.95%,
      rgba(255, 224, 162, 0.41) 97.37%
    );

    border: 2px dashed $fifth_color;
    border-radius: 15px;
    width: 92%;
    padding: 10px 18px;
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 38px;
      margin: 0;
      text-transform: capitalize;
      span {
        font-size: 20px;
        font-style: italic;
        font-weight: 600;
        line-height: 38px;
        color: #b74d00;
        background-color: #ffe6b6;
        padding: 0px 6px;
        border-radius: 20px;
      }
    }
  }
  button {
    box-shadow: 0px 7px 7.8px 0px #3f280880;
  }
}
//common background
.common-background {
  background-color: $secondary_color;
  img {
    position: absolute;
    bottom: 0;
  }
  .button-section {
    padding: 3% 18%;
    button {
      // width: 30%;
      &:first-child {
        margin-right: 20px;
      }
      &:last-child {
        background-color: $forth_color;
      }
    }
  }
}
//Ready
.ready-section {
  position: relative;
  z-index: 1;
  padding: 4% 0%;
  h6 {
    text-transform: capitalize;
  }
  button {
    box-shadow: 0px 7px 7.8px 0px #c5a67a80;
  }
  h2 {
    span {
      color: $ternary_color;
    }
  }
}
//Contact
.contact-form-modal {
  .modal-header {
    border-bottom: unset;
  }
  .modal-body {
    background-color: $sixth_color;
  }
  .modal-content {
    border: none;
  }
  .form-control {
    padding: 4% 7%;
  }
  input {
    background-color: $secondary_color;
    border: none;
    border-radius: 10px;
    &::-webkit-input-placeholder {
      font-size: 12px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      line-height: 24px;
      color: #111111;
    }
    &:focus {
      outline: none !important;
      border: none;
      box-shadow: none;
    }
  }
  .datepicker-container {
    position: relative;
    width: 100%;
    .react-datepicker-wrapper {
      width: 100%;
      input {
        font-size: 12px;
        outline: none;
        border: none;
        border-radius: 10px;
        padding: 5% 7%;
        width: 100%;
      }
    }
    .calendar-icon {
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }
  .schedule-button {
    border-radius: 13px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
    padding: 3% 14%;
  }
  .btn-close {
    &:active {
      border: none;
      box-shadow: none;
      outline: 0;
    }
    &:focus {
      border: none;
      box-shadow: none;
      outline: 0;
    }
  }
  .authorize-label {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  .form-check-input {
    &:checked {
      background-color: $primary_color;
    }
  }
}
//Footer
.footer {
  .footer-logo-column {
    border-right: 1px solid $primary_color;
    display: flex;
    flex-direction: column;
    .footer-logo {
      margin-right: 30px;
      flex: 1;
      img {
        height: 46px;
      }
    }
  }
  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding-left: unset;

    font-weight: 400;
    font-size: 12px;
    li {
      cursor: pointer;
      // color: $secondary_color;
      a {
        cursor: pointer;
        // color: $secondary_color;
      }
    }
  }
  .footer-first-row {
    border-right: 1px solid $primary_color;
  }
  .footer-header {
    // color: $ternary_color;
    font-weight: 400;
    margin-right: 6%;
  }
  .address-text {
    margin: unset;
    font-size: 16px;
  }
  svg {
    margin-right: 20px;
  }
  .disclaimer {
    font-size: 11px;
    text-align: center;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .copyright {
    background-color: #fff;
    color: $primary_color;
    text-align: center;
    padding: 10px 0px;
    p {
      // font-size: 12px;
      font-family: "Gotham-Light", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.6px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .common-background {
    padding: 9% 0% !important;
  }
}

@media only screen and (max-width: 991px) {
  //Banner
  .banner {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    position: relative;
    &::before {
      z-index: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.862368) 57.85%,
        rgba(0, 0, 0, 0.96) 100%
      );
    }
    .mobile-nav {
      position: absolute;
      top: 11px;
      right: 10px;
    }
    .banner-title {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      margin-bottom: 50%;
    }
    .menu-toggle {
      position: relative;
      z-index: 2;
    }
    .banner-text {
      top: 5%;
      position: relative;
      z-index: 2;
      text-align: center;
      padding-right: unset;
      h1 {
        padding: 0 17%;
      }
    }
    button {
      width: 60% !important;
      &:first-child {
        margin-right: unset !important;
      }
    }
  }
  .full-screen-modal {
    .modal-dialog {
      position: fixed; // Override Bootstrap’s absolute positioning
      right: 0; // Align to the right
      top: 0;
      height: 100vh;
      margin: 0;
      display: flex;
      transform: translateX(100%); // Start off-screen to the right
      transition: transform 0.4s ease-in-out; // Smooth transition

      &.slide-in {
        transform: translateX(0); // Move to on-screen position
      }
    }

    .modal-content {
      height: 100%;
      width: 100vw;
      border-radius: unset;
    }
    ul {
      padding-left: unset;
      li {
        padding: 8px 0px;
        &:not(:last-child) {
          border-bottom: 1px solid #dededec3;
        }
      }
    }
  }
  //Modern Section
  .modern-section {
    padding-bottom: unset !important;
    .first-span {
      font-size: 8.84px;
      font-weight: 300;
      line-height: 11.76px;
      margin-bottom: unset;
    }
    .sec-span {
      font-size: 9px;
      font-weight: 600;
      line-height: 12px;
      margin-bottom: unset;
    }
    .facility-row {
      padding: 6px 3px 6px 8px;
      height: 52px;
      border-radius: 5px;
      .facility-img {
        padding: 9px;
        border-radius: 5px;
      }
    }
    .prime-kollur {
      border-radius: 21px;
      right: 17px;
      top: 12%;
      border: 1px solid #fff;
      h6 {
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        margin-left: 5px;
      }
    }
    .section2-img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 330px;
    }
  }
  //Amenities
  .amenities-section {
    .amenities-list {
      margin: 0% 12% 0% 0%;
      p {
        font-size: 8px;
        font-weight: 400;
        line-height: 12px;
      }
    }
    .image-div {
      img {
        &:first-child {
          width: 55%;
          right: 22% !important;
        }
        &:last-child {
          width: 27%;
        }
      }
    }
  }
  //Location
  .location-section {
    .duration-row{
      .location {
        padding: 9px 14px 11px 11px;
        height: 45px;
        p {
          font-size: 9px;
          line-height: 12px;
        }
      }
      .duration {
        // padding: 3% 1%;
        padding: 8px 8px 9px 0px;
        height: 45px;
        .duration-div {
          .time-display {
            p {
              &:first-child {
                font-size: 12px;
                line-height: 10px;
              }
              &:last-child {
                font-size: 9px;
                line-height: 10px;
              }
            }
          }
        }
      }
    }
    
  }
  //About
  .about-section {
    ul {
      li {

        &:not(:last-child) {
          padding-bottom: 3px;
        }
        svg {
          font-size: 14px;
          margin-right: 9px;
        }
        p {
          font-size: 12px;
          font-weight: 300;
          line-height: 15px;
        }
      }
    }
  }
  //Facing
  .facing-section {
    .facing-div {
      p {
        &:first-child {
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
        }
        &:last-child {
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
        }
      }
      ul {
        padding-left: 13px;
        li {
          font-size: 7px;
          font-weight: 300;
          line-height: 11.25px;
        }
      }
    }
  }
  //Testimony
  .testimony-section {
    .card {
      padding: 0px;
      .user-content {
        .user-discription {
          p {
            font-size: 6.4px;
            font-weight: 300;
            line-height: 10px;
          }
        }
        .user-data {
          img {
            height: 20px;
            margin-right: 7px;
          }
          p {
            &:first-child {
              font-size: 7.2px;
              font-weight: 600;
              line-height: 10.8px;
            }
            &:last-child {
              font-size: 5.6px;
              font-weight: 300;
              line-height: 8.4px;
            }
          }
        }
      }
      .rating-star {
        svg {
          font-size: 10px;
        }
      }
    }
  }
  //Offer
  .offer-section {
    .row {
      margin-top: 66%;
    }
    h4 {
      font-size: 14px !important;
    }
    .offer-coupan {
      width: 100%;
      padding: 6px 9px;
      p {
        font-size: 12px ;
        //   line-height: normal !important;
        span {
          font-size: 12px ;
          // line-height: normal !important;
        }
      }
    }
    button {
      padding: 14px 14px;
    }
  }
  //Ready
  .ready-section {
    padding: unset;
  }
  //footer
  .footer {
    .address-text{
      font-size: 9px;
    }
    .copyright {
      p {
        margin: 0;
      }
    }
    .footer-logo-column {
      ul {
        display: block;
        li {
          padding-bottom: 5px;
        }
      }
    }
    .footer-logo {
      img {
        &:last-child {
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  //common background
  .common-background {
    .button-section {
      padding: 3% 16%;
    }
  }
}

@media only screen and (max-width: 768px) {
  //common background
  .common-background {
    .button-section {
      padding: 3% 7%;
    }
  }
}
@media only screen and (max-width: 550px) {
  //Facing
  .facing-section {
    .facing-div {
      button {
        padding: 10px 8px;
      }
    }
  }
  //Testimony
  .testimony-section {
    button {
      padding: 14px 28px !important;
    }
  }
  //common background
  .common-background {
    .button-section {
      padding: 3% 0%;
    }
    button {
      padding: 14px 7px;
    }
  }
  //footer
  .footer {
    .copyright {
      p {
        padding: 0px 20px;
        font-size: 11px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  //amenities
  .amenities-section {
    h6 {
      padding-right: 30px;
    }
  }
  //common background
  .common-background {
    .button-section {
      // padding: 3% 0%;
    }
  }
  //footer
  .footer {
    .copyright {
      p {
        padding: 0px 20px;
        font-size: 8px;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .offer-coupan {
    p {
      font-size: 9px !important;
      span {
        font-size: 9px !important;
      }
    }
  }
}
