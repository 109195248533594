.mb-50 {
  margin-bottom: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-7 {
  margin-top: 7%;
}
.mb-7 {
  margin-bottom: 7%;
}
.ml-50{
    margin-left: 50px;
}
.mr-50{
    margin-right: 50px;
}
.ml-20{
    margin-left: 20px;
}
.mr-20{
    margin-right: 20px;
}
.mb-20{
    margin-bottom: 20px;
}
.mt-20{
    margin-top: 20px;
}
.pt-30{
    padding-top: 30px;
}
.pb-30{
    padding-bottom: 30px;
}
.py-30{
    padding: 30px 0px;
}
.py-50{
    padding: 50px 0px;
}
.px-30{
    padding: 0px 30px;
}
.px-20{
    padding: 0px 20px;
}
ul {
  list-style-type: none;
}
.common-btn {
  font-size: 18px;
  font-weight: 400;
  border: unset;
  border-radius: 13px;
  padding: 16px 60px;
}
.error {
  color: red;
}
h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 50.6px;
  margin: 0;
}
h2 {
  font-size: 44px;
  font-weight: 600;
  line-height: 55px;
  color: $primary_color;
  margin: 0;
}
h6 {
  font-size: 21px;
  font-weight: 400;
  line-height: 31.5px;
  margin: 0;
}
p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.primary-color {
  color: $primary_color;
}
.primary-background{
    background-color: $primary_color;
}
.section-padding {
  padding: 5% 5%;
}
.float {
  background-color: #25d366;
  border-radius: 50px;
  bottom: 40px;
  box-shadow: 2px 2px 3px #999;
  color: #fff;
  font-size: 33px;
  height: 51px;
  position: fixed;
  right: 40px;
  text-align: center;
  width: 51px;
  z-index: 100;
}
.my-float {
  margin-top: -7px;
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 28px;
    line-height: 32.2px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px !important;
  }
  h6 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
  }
  p{
    font-size: 9px ;
    font-weight: 400 ;
    line-height: 12px ;
  }
  .common-btn{
    font-size: 9px;
    font-weight: 500;
    border-radius: 3px;
    padding: 14px 50px;
  }
  .section-padding {
    padding: 10% 5%;
  }
}
